import * as React from 'react';
import { CustomerSummary } from './Analytics';

export interface Props {
  rows: CustomerSummary[];
  selectedCustomers: Set<number>;
  ignoredCustomers: Set<number>;
  onCustomerChange: (key: number) => any;
  showName: boolean;
}

export interface State {
  search: string;
  page: number;
  sortColumn: string;
  sortReverse: boolean;
  perPage: number;
}

class CustomerList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);

    this.state = { search: ''
                 , page: 0
                 , sortColumn: 'total'
                 , sortReverse: true
                 , perPage: 7
                 };
  }

  handleSearchChange(e: { target: HTMLInputElement }) {
    this.setState({ search: e.target.value });
  }

  handleSortColumn(column: string) {
    this.setState(prevState => {
      if (prevState.sortColumn === column) {
        return { sortColumn: prevState.sortColumn, sortReverse: !prevState.sortReverse };
      } else {
        return { sortColumn: column, sortReverse: true };
      }
    });
  }

  handleUpdatePage(e: { target: HTMLInputElement }) {
    this.setState({ page: Number(e.target.value) - 1 });
  }

  render() {
    let filteredRows;
    if (this.props.showName) {
        filteredRows = this.props.rows.filter(row => row.customerId.toString().startsWith(this.state.search));
    } else {
        filteredRows = this.props.rows.filter(row => row.customerName.startsWith(this.state.search));
    }
    filteredRows.sort(columnComparator(this.state));

    let page = this.state.page;
    if (page  < 0 || this.state.perPage * page >= filteredRows.length) { page = 0; }

    const numPages = Math.ceil(filteredRows.length / this.state.perPage);
    const rows = filteredRows.slice(this.state.perPage * page, this.state.perPage * (page + 1));

    let idColumn = !this.props.showName
                 ? <th onClick={e => this.handleSortColumn('customerId')}>Customer ID</th>
                 : <th onClick={e => this.handleSortColumn('customerName')}>Customer Name</th>;

    return (
      <div>
        <input type="text" onChange={this.handleSearchChange} value={this.state.search} placeholder="Search for Customer ID" />
        &nbsp;
        <span>
          Displaying page:
          <input type="number" onChange={this.handleUpdatePage} value={this.state.page + 1} min={1} max={numPages} style={{width: '4em'}} />
          &nbsp; of &nbsp; {numPages}
        </span>
        <table className="table table-striped table-sm">
        <tbody>
          <tr>
            <th />
            {idColumn}
            <th onClick={e => this.handleSortColumn('total')}>Total requests</th>
            <th onClick={e => this.handleSortColumn('last')}>Last request</th>
          </tr>
          {rows.map(item => (
             <tr key={item.customerId}>
               <td>
                 <input
                     type="checkbox"
                     checked={this.props.selectedCustomers.has(item.customerId)}
                     onChange={e => this.props.onCustomerChange(item.customerId)}
                 />
               </td>
               <td><CustomerId id={item.customerId} name={item.customerName} showName={this.props.showName} /></td>
               <td>{item.total}</td>
               <td>{item.last.toLocaleString()}</td>
             </tr>
          ))}
        </tbody>
        </table>
      </div>
    );
  }
}

export default CustomerList;

function CustomerId(props: { id: number, name: string, showName: boolean }) {
  let display = props.showName ? props.name : props.id.toString();

  if (props.id === 0) {
    return (<>None</>);
  } else {
    return (<><a target="_blank" href={'https://app.cryptolens.io/Customer/Edit/' + props.id}>{display}</a></>);
  }
}

function cmp(a: any, b: any): number {
  if (a < b) { return -1; }
  if (a > b) { return  1; }
  return 0;
}

function columnComparator(state: { sortColumn: string, sortReverse: boolean }) {
  let c = 1; if (state.sortReverse) { c = -1; }
  return function(a: CustomerSummary, b: CustomerSummary) { return c * cmp(a[state.sortColumn], b[state.sortColumn]); };
}
