import * as React from 'react';
import './App.css';
import KeyList from './components/KeyList';
import Analytics from './components/Analytics';

const logo = require('./logo.svg');

class App extends React.Component {
  render() {
    return (
      <div className="container">
        <Analytics />
      </div>
    );
  }
}

export default App;
