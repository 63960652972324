import * as React from 'react';
import { LicenseKey, LicenseKeySummary } from './Analytics';

export interface Props {
  rows: LicenseKeySummary[];
  selectedLicenseKeys: Map<string, LicenseKey>;
  ignoredLicenseKeys: Map<string, LicenseKey>;
  onKeyChange: (key: LicenseKey) => any;
}

export interface State {
  search: string;
  page: number;
  sortColumn: string;
  sortReverse: boolean;
  perPage: number;
}

class KeyList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSortColumn = this.handleSortColumn.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);

    this.state = { search: ''
                 , page: 0
                 , sortColumn: 'total'
                 , sortReverse: true
                 , perPage: 7
                 };
  }

  handleSearchChange(e: { target: HTMLInputElement }) {
    this.setState({ search: e.target.value });
  }

  handleSortColumn(column: string) {
    this.setState(prevState => {
      if (prevState.sortColumn === column) {
        return { sortColumn: prevState.sortColumn, sortReverse: !prevState.sortReverse };
      } else {
        return { sortColumn: column, sortReverse: true };
      }
    });
  }

  handleUpdatePage(e: { target: HTMLInputElement }) {
    this.setState({ page: Number(e.target.value) - 1 });
  }

  render() {
    const filteredRows = this.props.rows.filter(row => row.licenseKey.toString().startsWith(this.state.search));
    filteredRows.sort(columnComparator(this.state));

    let page = this.state.page;
    if (page  < 0 || this.state.perPage * page >= filteredRows.length) { page = 0; }

    const numPages = Math.ceil(filteredRows.length / this.state.perPage);
    const rows = filteredRows.slice(this.state.perPage * page, this.state.perPage * (page + 1));

    return (
      <div>
        <input type="text" onChange={this.handleSearchChange} value={this.state.search} placeholder="Search for Key ID" />
        &nbsp;
        <span>
          Displaying page:
          <input type="number" onChange={this.handleUpdatePage} value={this.state.page + 1} min={1} max={numPages} style={{width: '4em'}} />
          &nbsp; of &nbsp; {numPages}
        </span>
        <table className="table table-striped table-sm">
        <tbody>
          <tr>
            <th />
            <th onClick={e => this.handleSortColumn('productId')}>Product Id</th>
            <th onClick={e => this.handleSortColumn('licenseKey')}>Key Id</th>
            <th onClick={e => this.handleSortColumn('total')}>Total requests</th>
            <th onClick={e => this.handleSortColumn('last')}>Last request</th>
          </tr>
          {rows.map(item => (
             <tr key={item.productId.toString() + ',' + item.licenseKey.toString()}>
               <td>
                 <input
                     type="checkbox"
                     checked={this.props.selectedLicenseKeys.has(item.productId + ',' + item.licenseKey)}
                     onChange={e => this.props.onKeyChange({ productId: item.productId, licenseKeyId: item.licenseKey })}
                 />
               </td>
               <td>{item.productId}</td>
               <td><a target="_blank" href={'https://app.cryptolens.io/Product/Detail/' + item.productId + '?find=GlobalID%3D' + item.licenseKey + '&show=True'}>{item.licenseKey}</a></td>
               <td>{item.total}</td>
               <td>{item.last.toLocaleString()}</td>
             </tr>
          ))}
        </tbody>
        </table>
      </div>
    );
  }
}

export default KeyList;

function cmp(a: any, b: any): number {
  if (a < b) { return -1; }
  if (a > b) { return  1; }
  return 0;
}

function columnComparator(state: { sortColumn: string, sortReverse: boolean }) {
  let c = 1; if (state.sortReverse) { c = -1; }
  return function(a: LicenseKeySummary, b: LicenseKeySummary) { return c * cmp(a[state.sortColumn], b[state.sortColumn]); };
}
